<template>
	<nav class="sm:py-5" :class="[isSmMenu == true ? 'bg-[#3A3A3A]' : 'bg-[#0E1310]']">
		<div class="w-full flex justify-around sm:justify-between items-center">
			<div class="hidden sm:ml-5 flex justify-center items-start cursor-pointer" @click="movePage('/')">
				<div><img src="@/assets/images/tiny_nav_logo.svg" /></div>
			</div>
			<div class="sm:ml-5 flex justify-center items-start cursor-pointer" @click="movePage('/')">
				<div><img src="@/assets/images/sm_nav_logo.svg" /></div>
			</div>
			<div></div>
			<div class="flex justify-center items-center font-light text-sm">
				<div class="sm:hidden group relative cursor-pointer">
					<div class="py-6 flex px-4 group-hover:text-[#1ADF52]">
						<div class="flex justify-center items-center">
							<div class="sm:text-sm text-white">{{ $t('word.navAbout') }}</div>
							<div class="ml-2"><img src="@/assets/images/nav_arrow.svg" /></div>
						</div>
					</div>
				</div>
				<div class="sm:hidden group relative cursor-pointer">
					<div class="py-6 flex px-4 group-hover:text-[#1ADF52]">
						<div class="flex justify-center items-center">
							<div class="sm:text-sm text-white">{{ $t('word.navContent') }}</div>
							<div class="ml-2"><img src="@/assets/images/nav_arrow.svg" /></div>
						</div>
					</div>
					<div class="invisible absolute z-50 w-96 flex flex-col py-4 px-4 group-hover:visible">
						<div class="py-2 dropdown-menu">
							<div class="px-3 py-1 flex justify-start items-start">
								<div class="px-6 py-2 w-full flex dropdown-over">
									<div><img src="@/assets/images/nav_icon_wallet.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div class="block dropdown-color">{{ $t('word.navSubMining') }}</div>
										<div class="text-sm font-normal">{{ $t('message.navSubMining') }}</div>
									</div>
								</div>
							</div>
							<div class="h-1"></div>
							<div class="h-px bg-[#999999]"></div>
							<div class="h-1"></div>
							<div class="px-3 py-1 flex justify-start items-start">
								<div class="px-6 py-2 w-full flex dropdown-over">
									<div><img src="@/assets/images/nav_icon_wexex.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div class="block dropdown-color">{{ $t('word.navSubGame') }}</div>
										<div class="text-sm font-normal">{{ $t('message.navSubGame') }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="sm:hidden group relative cursor-pointer">
					<div class="py-6 flex px-4 group-hover:text-[#1ADF52]">
						<div class="flex justify-center items-center">
							<div class="sm:text-sm text-white">{{ $t('word.navEcosystem') }}</div>
							<div class="ml-2"><img src="@/assets/images/nav_arrow.svg" /></div>
						</div>
					</div>
					<div class="invisible absolute z-50 w-96 flex flex-col py-4 px-4 group-hover:visible">
						<div class="py-2 dropdown-menu">
							<div class="px-3 py-1 flex justify-start items-start">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="goWallet">
									<div><img src="@/assets/images/nav_icon_wallet.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div class="block dropdown-color">{{ $t('word.navSubWallet') }}</div>
										<div class="text-sm font-normal">{{ $t('message.navSubWallet') }}</div>
									</div>
								</div>
							</div>
							<div class="h-1"></div>
							<div class="h-px bg-[#999999]"></div>
							<div class="h-1"></div>
							<div class="px-3 py-1 flex justify-start items-start">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="goWexex">
									<div><img src="@/assets/images/nav_icon_wexex.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div class="block dropdown-color">{{ $t('word.navSubWexex') }}</div>
										<div class="text-sm font-normal">{{ $t('message.navSubWexex') }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="sm:hidden group relative cursor-pointer">
					<div class="py-6 flex px-4 group-hover:text-[#1ADF52]">
						<div class="flex justify-center items-center">
							<div class="sm:text-sm text-white">{{ $t('word.navCommunity') }}</div>
							<div class="ml-2"><img src="@/assets/images/nav_arrow.svg" /></div>
						</div>
					</div>
					<div class="invisible absolute z-50 w-96 flex flex-col py-4 px-4 group-hover:visible">
						<div class="py-2 dropdown-menu">
							<div class="px-3 py-1 flex justify-start items-start">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('blog')">
									<div><img src="@/assets/images/nav_icon_blog.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div class="block dropdown-color">{{ $t('word.navSubBlog') }}</div>
										<div class="text-sm font-normal">{{ $t('message.navSubBlog') }}</div>
									</div>
								</div>
							</div>
							<div class="h-1"></div>
							<div class="h-px bg-[#999999]"></div>
							<div class="h-1"></div>
							<div class="px-3 py-1 flex justify-start items-start">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('event')">
									<div><img src="@/assets/images/nav_icon_event.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div class="block dropdown-color">{{ $t('word.navSubEvent') }}</div>
										<div class="text-sm font-normal">{{ $t('message.navSubEvent') }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="sm:hidden ml-12 sm:ml-10 sm:text-sm cursor-pointer">
					<template v-if="store.state.userId != ''">
						<div v-if="router.currentRoute.value.name == 'profile'" @click="movePage('profile')">
							<img src="@/assets/images/nav_login_over.svg" />
						</div>
						<div v-else @click="movePage('profile')">
							<img src="@/assets/images/nav_login.svg" />
						</div>
					</template>
					<div v-else class="hover:text-[#1ADF52] text-white" @click="movePage('login')">{{ $t('word.navLogin') }}</div>
				</div>
				<div class="sm:hidden md:hidden ml-5 text-white">
					<template v-if="store.state.userId != ''">
						<div class="flex justify-center items-center">
							<div><img src="@/assets/images/seed.png" /></div>
							<div class="ml-2">{{ Number(store.state.seed).toLocaleString() }}</div>
						</div>
					</template>
				</div>
				<div class="ml-10 flex justify-center items-center">
					<div class="relative select-locale" data-te-dropdown-position="dropup">
						<button
							class="px-5 py-2 flex justify-center items-center text-sm"
							type="button"
							id="selectLocale"
							data-te-dropdown-toggle-ref
							aria-expanded="false"
							data-te-ripple-init
							data-te-ripple-color="light"
						>
							<img :src="localeSelectImage" />
							<span class="ml-5 w-2">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5">
									<path
										fill-rule="evenodd"
										d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
										clip-rule="evenodd"
									/>
								</svg>
							</span>
						</button>
						<ul
							class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
							aria-labelledby="selectLocale"
							data-te-dropdown-menu-ref
						>
							<template v-for="lo in locales" :key="lo.name">
								<li>
									<div
										@click="changeLocale(lo.name)"
										class="block w-full whitespace-nowrap bg-transparent px-5 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600 cursor-pointer"
										data-te-dropdown-item-ref
									>
										<img :src="require(`@/assets/images/locale_${lo.name}.png`)" />
									</div>
								</li>
							</template>
						</ul>
					</div>
				</div>
				<div class="sm:mr-5 md:hidden lg:hidden ml-12 sm:ml-10 sm:text-sm cursor-pointer">
					<div class="flex justify-center items-center">
						<div v-if="isSmMenu == false" @click="showSmMenu"><img src="@/assets/images/sm_menu_nav.svg" /></div>
						<div v-else @click="hideSmMenu"><img src="@/assets/images/sm_btn_close.svg" /></div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="isSmMenu == true" class="w-full flex flex-col justify-center items-center bg-[#3A3A3A]">
			<div class="h-5"></div>
			<div class="w-full h-px bg-[#7B7B7B]"></div>
			<div class="h-5"></div>
			<div class="w-full flex flex-col justify-center items-center">
				<div v-if="store.state.userId != ''" class="px-8 w-full flex justify-between items-center">
					<div class="flex justify-center items-center cursor-pointer" @click="movePage('profile')">
						<div><img src="@/assets/images/nav_login.svg" /></div>
						<div class="ml-3 text-sm text-white">{{ $t('word.navSmInfor') }}</div>
					</div>
				</div>
				<div v-if="store.state.userId != ''" class="h-8"></div>
				<div class="px-8 w-full flex justify-between items-center cursor-pointer">
					<div class="text-xl g-color">{{ $t('word.navAbout') }}</div>
				</div>
				<div class="h-8"></div>
				<div @click="toggleContent" class="px-8 w-full flex justify-between items-center cursor-pointer">
					<div class="text-xl g-color">{{ $t('word.navContent') }}</div>
					<div v-if="isSmMenuContent == false"><img src="@/assets/images/sm_btn_nav_right.svg" /></div>
					<div v-else><img src="@/assets/images/sm_btn_nav_down.svg" /></div>
				</div>
				<div v-if="store.state.userId == ''" class="h-8"></div>
				<div v-if="isSmMenuContent == true" class="h-8"></div>
				<div v-if="isSmMenuContent == true" class="p-5 w-full flex flex-col justify-center items-start bg-white">
					<div class="px-3 py-1 flex justify-start items-start">
						<div class="px-6 py-2 w-full flex dropdown-over cursor-pointer">
							<div><img src="@/assets/images/nav_icon_wallet.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div class="block dropdown-color">{{ $t('word.navSubMining') }}</div>
								<div class="text-sm font-normal">{{ $t('message.navSubMining') }}</div>
							</div>
						</div>
					</div>
					<div class="h-1"></div>
					<div class="w-full h-px bg-[#999999]"></div>
					<div class="h-1"></div>
					<div class="px-3 py-1 flex justify-start items-start">
						<div class="px-6 py-2 w-full flex dropdown-over cursor-pointer">
							<div><img src="@/assets/images/nav_icon_wexex.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div class="block dropdown-color">{{ $t('word.navSubGame') }}</div>
								<div class="text-sm font-normal">{{ $t('message.navSubGame') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="store.state.userId != ''" class="h-8"></div>
				<div @click="toggleEcosystem" class="px-8 w-full flex justify-between items-center cursor-pointer">
					<div class="text-xl g-color">{{ $t('word.navEcosystem') }}</div>
					<div v-if="isSmMenuEcosystem == false"><img src="@/assets/images/sm_btn_nav_right.svg" /></div>
					<div v-else><img src="@/assets/images/sm_btn_nav_down.svg" /></div>
				</div>
				<div v-if="isSmMenuEcosystem == true" class="h-8"></div>
				<div v-if="isSmMenuEcosystem == true" class="p-5 w-full flex flex-col justify-center items-start bg-white">
					<div class="px-3 py-1 flex justify-start items-start">
						<div class="px-6 py-2 w-full flex dropdown-over cursor-pointer" @click="goWallet">
							<div><img src="@/assets/images/nav_icon_wallet.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div class="block dropdown-color">{{ $t('word.navEcosystem') }}</div>
								<div class="text-sm font-normal">{{ $t('message.navSubWallet') }}</div>
							</div>
						</div>
					</div>
					<div class="h-1"></div>
					<div class="w-full h-px bg-[#999999]"></div>
					<div class="h-1"></div>
					<div class="px-3 py-1 flex justify-start items-start">
						<div class="px-6 py-2 w-full flex dropdown-over cursor-pointer" @click="goWexex">
							<div><img src="@/assets/images/nav_icon_wexex.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div class="block dropdown-color">{{ $t('word.navSubWexex') }}</div>
								<div class="text-sm font-normal">{{ $t('message.navSubWexex') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="h-8"></div>
				<div @click="toggleCommunity" class="px-8 w-full flex justify-between items-center cursor-pointer">
					<div class="text-xl g-color">{{ $t('word.navCommunity') }}</div>
					<div v-if="isSmMenuCommunity == false"><img src="@/assets/images/sm_btn_nav_right.svg" /></div>
					<div v-else><img src="@/assets/images/sm_btn_nav_down.svg" /></div>
				</div>
				<div v-if="isSmMenuCommunity == true" class="h-8"></div>
				<div v-if="isSmMenuCommunity == true" class="p-5 w-full flex flex-col justify-center items-start bg-white">
					<div class="px-3 py-1 flex justify-start items-start">
						<div class="px-6 py-2 w-full flex dropdown-over cursor-pointer" @click="movePage('blog')">
							<div><img src="@/assets/images/nav_icon_wallet.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div class="block dropdown-color">{{ $t('word.navSubBlog') }}</div>
								<div class="text-sm font-normal">{{ $t('message.navSubBlog') }}</div>
							</div>
						</div>
					</div>
					<div class="h-1"></div>
					<div class="w-full h-px bg-[#999999]"></div>
					<div class="h-1"></div>
					<div class="px-3 py-1 flex justify-start items-start">
						<div class="px-6 py-2 w-full flex dropdown-over cursor-pointer" @click="movePage('event')">
							<div><img src="@/assets/images/nav_icon_wexex.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div class="block dropdown-color">{{ $t('word.navSubEvent') }}</div>
								<div class="text-sm font-normal">{{ $t('message.navSubEvent') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="store.state.userId == ''" class="h-8"></div>
				<div v-if="store.state.userId == ''" @click="movePage('login')" class="px-28 py-2 sm-login-btn cursor-pointer">
					{{ $t('word.navLogin') }}
				</div>
				<div class="h-5"></div>
			</div>
		</div>
	</nav>
	<router-view v-if="isMobile == false" />
	<router-view v-else name="mobile" />
	<footer class="main-footer">
		<div class="footer-bg">
			<div class="h-10"></div>
			<div
				class="grid grid-cols-[300px_300px_300px_300px] sm:grid-cols-[300px] md:grid-cols-[200px_200px_200px_200px] gap-y-8 place-content-center items-start text-white"
			>
				<!-- <div class="w-full flex justify-center items-start space-x-36 sm:space-x-12 md:space-x-16 text-white"> -->
				<div class="flex flex-col justify-center items-start">
					<div class="text-2xl">{{ $t('word.siteMapExplore') }}</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer" @click="goWallet">{{ $t('word.siteMapWallet') }}</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer" @click="goWexex">{{ $t('word.siteMapWexex') }}</div>
				</div>
				<div class="flex flex-col justify-center items-start">
					<div class="text-2xl">{{ $t('word.siteMapParticipate') }}</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer" @click="movePage('blog')">{{ $t('word.siteMapBlog') }}</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer" @click="movePage('event')">{{ $t('word.siteMapEvent') }}</div>
				</div>
				<div class="flex flex-col justify-center items-start">
					<div class="text-2xl">{{ $t('word.siteMapAbout') }}</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer">{{ $t('word.siteMapPrivacy') }}</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer">{{ $t('word.siteMapCompany') }}</div>
				</div>
				<div class="flex flex-col justify-center items-start">
					<div class="text-2xl">{{ $t('word.siteMapContact') }}</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer">
						<a href="mailto:support@wexex.io">{{ $t('word.siteMapCustomer') }}</a>
					</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer">
						<a href="mailto:listing@wexex.io">{{ $t('word.siteMapListing') }}</a>
					</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer">
						<a href="mailto:initial@wexexio">{{ $t('word.siteMapIEO') }}</a>
					</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer">
						<a href="mailto:partnership@wexex.io">{{ $t('word.siteMapPartner') }}</a>
					</div>
				</div>
			</div>
			<div class="h-6"></div>
			<div class="h-px bg-[#454F49]"></div>
			<div class="h-8"></div>
			<div class="w-full grid grid-cols-4 sm:grid-cols-1 gap-x-10 gap-y-8 place-content-center items-center text-xl text-white">
				<div class="sm:hidden"></div>
				<div class="col-span-2 text-center">WEX 2023 All rights reserved</div>
			</div>
			<div class="h-8"></div>
		</div>
	</footer>
	<!-- <LoginModal :visible="isShowLogin" @hide="hideLoginModal" @googleLogin="loginSdk"> </LoginModal> -->
	<!-- <ProfileModal :visible="isShowProfile" @hide="hideProfileModal" @googleLogout="logout"> </ProfileModal> -->
	<LoadingSpinner v-if="store.state.isLoading"></LoadingSpinner>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import store from '@/store';
import router from '@/router';
import axios from 'axios';
import http from '@/api/http';
import { googleTokenLogin, googleLogout, GoogleLogin } from 'vue3-google-login';
// import LoginModal from '@/components/LoginModal.vue';
// import ProfileModal from '@/components/ProfileModal.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import md5 from 'crypto-js/md5';
import { Dropdown, initTE } from 'tw-elements';
import { useI18n } from 'vue-i18n';
import { locales } from '@/config/constants/locale';

const { t, locale } = useI18n();

const isMobile = ref(false);
const isSmMenu = ref(false);
const isSmMenuContent = ref(false);
const isSmMenuEcosystem = ref(false);
const isSmMenuCommunity = ref(false);

const localeSelectImage = ref('');

onMounted(() => {
	initTE({ Dropdown });

	onResize();
	window.addEventListener('resize', onResize, { passive: true });

	isSmMenu.value = false;

	changeLocale(store.state.locale);
});

onUnmounted(() => {
	if (typeof window === 'undefined') return;
	window.removeEventListener('resize', onResize, { passive: true });
});

const onResize = () => {
	isSmMenu.value = false;

	/* 	if (window.innerWidth < 650 || window.screen.width < 650) {
		isMobile.value = true;
	} else {
		isMobile.value = false;
	} */
	// console.log(window.screen.width + ' => ' + isMobile.value);
};

const showSmMenu = () => {
	isSmMenu.value = true;
};

const hideSmMenu = () => {
	isSmMenu.value = false;
};

const toggleContent = () => {
	if (isSmMenuContent.value == true) {
		isSmMenuContent.value = false;
	} else {
		isSmMenuContent.value = true;
	}
};

const toggleEcosystem = () => {
	if (isSmMenuEcosystem.value == true) {
		isSmMenuEcosystem.value = false;
	} else {
		isSmMenuEcosystem.value = true;
	}
};

const toggleCommunity = () => {
	if (isSmMenuCommunity.value == true) {
		isSmMenuCommunity.value = false;
	} else {
		isSmMenuCommunity.value = true;
	}
};

const isShowLogin = ref(false);
const showLoginModal = () => {
	isShowLogin.value = true;
};
const hideLoginModal = () => {
	isShowLogin.value = false;
};

const isShowProfile = ref(false);
const showProfileModal = () => {
	isShowProfile.value = true;
};
const hideProfileModal = () => {
	isShowProfile.value = false;
};

const changeLocale = lang => {
	if (localeSelectImage.value != lang) {
		localeSelectImage.value = require('@/assets/images/locale_' + lang + '.png');

		if (lang != '' && store.state.locale != lang) {
			store.commit('setLocale', { lang });
			locale.value = lang;
		}
	}
};

const goWallet = () => {
	window.open('https://wallet.wexwallet.io/', '_blank');
};

const goWexex = () => {
	window.open('https://www.wexex.io/', '_blank');
};

const movePage = link => {
	isSmMenu.value = false;

	router.push(link);
};

onMounted(() => {});

const loginSdk = () => {
	googleTokenLogin()
		.then(response => {
			axios({
				method: 'GET',
				url: 'https://www.googleapis.com/oauth2/v3/userinfo?access_token=' + response.access_token
			}).then(userInfo => {
				// console.log(userInfo);
				login(userInfo.data);
			});
		})
		.catch(error => {
			console.log(error);
		});
};

const login = userData => {
	// 로그인시 기존 토큰 데이터 삭제
	store.commit('setInitToken');

	store.commit('setUserName', { userName: userData.name });
	store.commit('setUserEmail', { userEmail: userData.email });

	checkAccount(userData);
};

const logout = () => {
	googleLogout();
	store.commit('setClearToken');

	router.push('/');
};

const checkAccount = userData => {
	http
		.get('/auth/checkaccount', {
			params: { account: userData.sub }
		})
		.then(response => {
			console.log(response);

			googleSignup(userData);
		})
		.catch(error => {
			console.log(error);

			if (error.response.data.errorCode == 307) {
				googleLogin(userData);
			}
		});
};

const googleLogin = async userData => {
	let password = md5(userData.sub + userData.email);

	http
		.post('/auth/login', {
			account: userData.sub,
			password: password.toString()
		})
		.then(response => {
			if (response.data.data.status == 1) {
				store.commit('setAccessToken', {
					token: response.data.data.accessToken,
					expireAt: response.data.data.accessExpiresIn
				});
				store.commit('setRefreshToken', {
					token: response.data.data.refreshToken,
					expireAt: response.data.data.refreshExpiresIn
				});
				store.commit('setUserId', {
					userId: response.data.data.uid
				});
				store.commit('setUserEmail', {
					userEmail: response.data.data.email
				});
				store.commit('setUserName', {
					userName: response.data.data.name
				});
				store.commit('setUserCode', {
					userCode: response.data.data.referrerCode
				});

				router.push('/');
			}
		})
		.catch(error => {
			console.log(error);
		});
};

const googleSignup = async userData => {
	let password = md5(userData.sub + userData.email);

	http
		.post('/auth/signup', {
			account: userData.sub,
			password: password.toString(),
			email: userData.email,
			name: userData.name,
			referral: store.state.referral
		})
		.then(response => {
			console.log('res', response);

			if (response.data.data.status == 1) {
				googleLogin(userData);
			}
		})
		.catch(error => {
			console.log(error);
		});
};
</script>

<style scoped>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

#vanta_area {
	/* width: 100vw; */
	/* height: 100vh; */
	/* position: fixed; */
}

nav a {
	font-weight: bold;
	color: #2c3e50;
}

nav a.router-link-exact-active {
	color: #1adf52;
}

.dropdown-over:hover .dropdown-color {
	color: #1adf52;
}

.contact-btn {
	border-radius: 20px;
	background: #000;
	color: #1adf52;
}

.dropdown-menu {
	border-radius: 13px;
	background: #fff;
}

.main-footer {
	position: relative;
	margin-top: 0px;
}

.footer-bg {
	background: #292929;
}

.select-locale {
	border-radius: 14px;
	border: 1px solid #454f49;
	color: #454f49;
}

.sm-menu-bg-color {
	background: #3a3a3a;
}

.sm-login-btn {
	border-radius: 20px;
	background: #000;
	color: #1adf52;
}
</style>
