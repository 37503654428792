export default {
	common: {
		korean: '한국어',
		english: 'English',
		russian: 'русский',
		china: '中国(简体)',
		taiwan: '中國(繁體)',
		cambodian: 'កម្ពុជា។ '
	},

	word: {
		navEcosystem: 'ប្រព័ន្ធអេកូឡូស៊ី',
		navSubWallet: 'កាបូប',
		navSubWexex: 'WEXEX',
		navCommunity: 'សហគមន៍',
		navSubBlog: 'ប្លុក',
		navSubEvent: 'ព្រឹត្តិការណ៍',
		navLogin: 'ចូល',
		navSmInfor: 'ព័ត៌មានរបស់ខ្ញុំ',
		siteMapExplore: 'រុករក',
		siteMapWallet: 'WEX កាបូប',
		siteMapWexex: 'WEXEX',
		siteMapParticipate: 'ចូលរួម',
		siteMapBlog: 'ប្លុក',
		siteMapEvent: 'ព្រឹត្តិការណ៍',
		siteMapAbout: 'អំពី',
		siteMapPrivacy: 'គោលការណ៍​ភាព​ឯកជន',
		siteMapCompany: 'ព​ត៍​មាន​ក្រុមហ៊ុន',
		siteMapContact: 'ទាក់ទង​មក​ពួក​យើង',
		siteMapCustomer: 'ជំនួយអតិថិជន',
		siteMapListing: 'ពាក្យសុំចុះឈ្មោះ',
		siteMapIEO: 'ដាក់ពាក្យសុំ IEO',
		siteMapPartner: 'ភាពជាដៃគូ',
		navAbout: 'អំពីយើង',
		navContent: 'មាតិកា',
		navSubMining: 'ការជីកយករ៉ែ',
		navSubGame: 'ហ្គេម',
		btnOk: 'ពិនិត្យ'
	},

	message: {
		navSubWallet: 'ចូលទៅកាន់ WEX Wallet Home',
		navSubWexex: 'មើល​បន្ថែមទៀត​អំពី WEXEX នៅលើ Facebook',
		navSubBlog: 'សម្រាប់ការអាប់ដេតចុងក្រោយបំផុត សូមចូលទៅកាន់ប្លុកផ្ទះ',
		navSubEvent: 'ពិនិត្យមើលទំព័រព្រឹត្តិការណ៍',
		navSubMining: 'ចូលទៅកាន់ WEX Mining',
		navSubGame: 'មើល​បន្ថែមទៀត​អំពី WEX GameBOX ',
		referralMisssing: 'អ្នកត្រូវតែមានអ្នកណែនាំដើម្បីចុះឈ្មោះ។',
		referralReward: 'រង្វាន់ណែនាំត្រូវបានអនុវត្ត 10 នាទីបន្ទាប់ពីការចុះឈ្មោះ។'
	},

	error: {}
};
