export const localeTypes = {
	EN: 'en',
	KR: 'kr',
	RU: 'ru',
	KH: 'kh',
	CN: 'cn',
	TW: 'tw'
};

export const locales = [
	{
		name: localeTypes.EN,
		label: 'English',
		type: 'english'
	},
	{
		name: localeTypes.KR,
		label: '한국어',
		type: 'korean'
	},
	{
		name: localeTypes.RU,
		label: 'русский',
		type: 'russian'
	},
	{
		name: localeTypes.KH,
		label: 'កម្ពុជា។',
		type: 'cambodian'
	},
	{
		name: localeTypes.CN,
		label: '中国(简体)',
		type: 'china'
	},
	{
		name: localeTypes.TW,
		label: '中國(繁體)',
		type: 'taiwan'
	}
];
