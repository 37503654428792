export default {
	common: {
		korean: '한국어',
		english: 'English',
		russian: 'русский',
		china: '中国(简体)',
		taiwan: '中國(繁體)',
		cambodian: 'កម្ពុជា។ '
	},

	word: {
		navEcosystem: 'Экосистема',
		navSubWallet: 'Кошелек',
		navSubWexex: 'WEXEX',
		navCommunity: 'Сообщество',
		navSubBlog: 'Блог',
		navSubEvent: 'Событие',
		navLogin: 'Авторизоваться',
		navSmInfor: 'Моя информация',
		siteMapExplore: 'Исследовать',
		siteMapWallet: 'WEX-кошелек',
		siteMapWexex: 'WEXEX',
		siteMapParticipate: 'Участвовать',
		siteMapBlog: 'Блог',
		siteMapEvent: 'Событие',
		siteMapAbout: 'О',
		siteMapPrivacy: 'политика конфиденциальности',
		siteMapCompany: 'Информация о компании',
		siteMapContact: 'Связаться с нами',
		siteMapCustomer: 'Служба поддержки',
		siteMapListing: 'Запрос листинга',
		siteMapIEO: 'Заявка на IEO',
		siteMapPartner: 'Партнерство',
		navAbout: 'О НАС',
		navContent: 'Содержание',
		navSubMining: 'Горное дело',
		navSubGame: 'Игра',
		btnOk: 'проверять'
	},

	message: {
		navSubWallet: 'Перейти на главную страницу WEX-кошелька',
		navSubWexex: 'Узнайте больше о WEXEX',
		navSubBlog: 'Для получения последних обновлений посетите домашнюю страницу блога.',
		navSubEvent: 'Посетите страницу мероприятия',
		navSubMining: 'Перейти на WEX Майнинг',
		navSubGame: 'Узнайте больше о WEX GameBOX',
		referralMisssing: 'Для регистрации у вас должен быть рекомендатель.',
		referralReward: 'Реферальные вознаграждения применяются через 10 минут после регистрации.'
	},

	error: {}
};
