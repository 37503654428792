import { createApp } from 'vue';
import App from '@/App.vue';
import '@/registerServiceWorker';
import router from '@/router';
import store from '@/store';
import vue3GoogleLogin from 'vue3-google-login';
import { createI18n } from 'vue-i18n';
import en from '@/locale/en';
import kr from '@/locale/kr';
import ru from '@/locale/ru';
import kh from '@/locale/kh';
import cn from '@/locale/cn';
import tw from '@/locale/tw';
import LS from '@/utils/localStorage';
import { locales } from '@/config/constants/locale';

import '@/index.css';

const i18 = new createI18n({
	legacy: false,
	locale: LS.getItem('locale') || locales[0].name,
	fallbackLocale: 'en',
	missingWarn: false,
	fallbackWarn: false,
	silentTranslationWarn: true,
	messages: {
		en,
		kh,
		ru,
		cn,
		tw,
		kr
	}
});

const app = createApp(App)
	.use(store)
	.use(router)
	.use(i18)
	.provide(i18, '$t')
	.use(vue3GoogleLogin, { clientId: process.env.VUE_APP_GOOGLE_API_KEY });

router.isReady().then(() => {
	app.mount('#app');
});
