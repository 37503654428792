export default {
	common: {
		korean: '한국어',
		english: 'English',
		russian: 'русский',
		china: '中国(简体)',
		taiwan: '中國(繁體)',
		cambodian: 'កម្ពុជា។ '
	},

	word: {
		navEcosystem: '생태계',
		navSubWallet: '지갑',
		navSubWexex: 'WEXEX',
		navCommunity: '커뮤니티',
		navSubBlog: '블로그',
		navSubEvent: '이벤트',
		navLogin: '로그인',
		navSmInfor: '내정보',
		siteMapExplore: 'Explore',
		siteMapWallet: 'WEX 지갑',
		siteMapWexex: 'WEXEX',
		siteMapParticipate: '참가하기',
		siteMapBlog: '블로그',
		siteMapEvent: '이벤트',
		siteMapAbout: '정보',
		siteMapPrivacy: '개인 정보 정책',
		siteMapCompany: '회사정보',
		siteMapContact: '문의하기',
		siteMapCustomer: '고객지원',
		siteMapListing: '상장요청',
		siteMapIEO: 'IEO 신청',
		siteMapPartner: '협력관계',
		navAbout: '회사소개',
		navContent: '콘텐츠',
		navSubMining: '채광',
		navSubGame: '게임',
		btnOk: '확인'
	},

	message: {
		navSubWallet: 'WEX Wallet 사이트로 이동',
		navSubWexex: 'WEXEX에 대해 알아보기',
		navSubBlog: '최신업데이트를 보려면 블로그로 이동하세요.',
		navSubEvent: '이벤트 페이지를 확인하세요.',
		navSubMining: 'WEX Mining 바로가기',
		navSubGame: 'WEX GameBOX 자세히 알아보기',
		referralMisssing: '추천인이 있어야 가입이 가능합니다.',
		referralReward: '추천인 보상은 가입 10분 후에 적용됩니다.'
	},

	error: {}
};
