export default {
	common: {
		korean: '한국어',
		english: 'English',
		russian: 'русский',
		china: '中国(简体)',
		taiwan: '中國(繁體)',
		cambodian: 'កម្ពុជា។ '
	},

	word: {
		navEcosystem: '生态系统',
		navSubWallet: '钱包',
		navSubWexex: 'WEXEX',
		navCommunity: '社区',
		navSubBlog: '博客',
		navSubEvent: '事件',
		navLogin: '登录',
		navSmInfor: '我的信息',
		siteMapExplore: '探索',
		siteMapWallet: 'WEX 钱包',
		siteMapWexex: 'WEXEX',
		siteMapParticipate: '参加',
		siteMapBlog: '博客',
		siteMapEvent: '事件',
		siteMapAbout: '关于',
		siteMapPrivacy: '隐私政策',
		siteMapCompany: '公司信息',
		siteMapContact: '联系我们',
		siteMapCustomer: '客户支持',
		siteMapListing: '上市请求',
		siteMapIEO: 'IEO申请',
		siteMapPartner: '合伙',
		navAbout: '关于我们',
		navContent: '内容',
		navSubMining: '矿业',
		navSubGame: '游戏',
		btnOk: '查看'
	},

	message: {
		navSubWallet: '前往 WEX 钱包首页',
		navSubWexex: '了解有关 WEXEX 的更多信息',
		navSubBlog: '有关最新更新，请转到博客主页',
		navSubEvent: '查看活动页面',
		navSubMining: '前往WEX挖矿',
		navSubGame: '了解有关 WEX GameBOX 的更多信息',
		referralMisssing: '您必须有推荐人才能注册',
		referralReward: '推荐奖励在注册后 10 分钟后生效'
	},

	error: {}
};
