export default {
	common: {
		korean: '한국어',
		english: 'English',
		russian: 'русский',
		china: '中国(简体)',
		taiwan: '中國(繁體)',
		cambodian: 'កម្ពុជា។ '
	},

	word: {
		navEcosystem: '生態系',
		navSubWallet: '錢包',
		navSubWexex: 'WEXEX',
		navCommunity: '社群',
		navSubBlog: '博客',
		navSubEvent: '事件',
		navLogin: '登入',
		navSmInfor: '我的信息',
		siteMapExplore: '探索',
		siteMapWallet: 'WEX 皮夾',
		siteMapWexex: 'WEXEX',
		siteMapParticipate: '參加',
		siteMapBlog: '部落格',
		siteMapEvent: '事件',
		siteMapAbout: '關於',
		siteMapPrivacy: '隱私權政策',
		siteMapCompany: '公司資訊',
		siteMapContact: '聯絡我們',
		siteMapCustomer: '客戶支援',
		siteMapListing: '上市請求',
		siteMapIEO: 'IEO申請',
		siteMapPartner: '合夥',
		navAbout: '關於我們',
		navContent: '內容',
		navSubMining: '礦業',
		navSubGame: '遊戲',
		btnOk: '查看'
	},

	message: {
		navSubWallet: '前往 WEX 皮夾首頁',
		navSubWexex: '了解有关 WEXEX 的更多信息',
		navSubBlog: '有關最新更新，請前往部落格首頁',
		navSubEvent: '查看活動頁面',
		navSubMining: '前往WEX挖礦',
		navSubGame: '了解有關 WEX GameBOX 的更多信息',
		referralMisssing: '您必須有推薦人才能註冊',
		referralReward: '推薦獎勵在註冊後 10 分鐘生效'
	},

	error: {}
};
