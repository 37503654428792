import { createRouter, createWebHistory } from 'vue-router';

const routes = [
	{
		path: '/',
		name: 'home',
		components: {
			default: () => import('@/views/pc/HomeView.vue'),
			mobile: () => import('@/views/mobile/HomeView.vue')
		}
	},
	{
		path: '/blog',
		name: 'blog',
		components: {
			default: () => import('@/views/pc/BlogView.vue'),
			mobile: () => import('@/views/mobile/BlogView.vue')
		}
	},
	{
		path: '/event',
		name: 'event',
		components: {
			default: () => import('@/views/pc/EventView.vue'),
			mobile: () => import('@/views/mobile/EventView.vue')
		}
	},
	{
		path: '/detail',
		name: 'detail',
		components: {
			default: () => import('@/views/pc/DetailView.vue'),
			mobile: () => import('@/views/mobile/DetailView.vue')
		}
	},
	{
		path: '/login',
		name: 'login',
		//component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
		components: {
			default: () => import('@/views/pc/LoginView.vue'),
			mobile: () => import('@/views/mobile/LoginView.vue')
		}
	},
	{
		path: '/profile',
		name: 'profile',
		components: {
			default: () => import('@/views/pc/ProfileView.vue'),
			mobile: () => import('@/views/mobile/ProfileView.vue')
		}
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
