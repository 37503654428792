export default {
	common: {
		korean: '한국어',
		english: 'English',
		russian: 'русский',
		china: '中国(简体)',
		taiwan: '中國(繁體)',
		cambodian: 'កម្ពុជា។ '
	},

	word: {
		navEcosystem: 'Ecosystem',
		navSubWallet: 'Wallet',
		navSubWexex: 'WEXEX',
		navCommunity: 'Community',
		navSubBlog: 'Blog',
		navSubEvent: 'Event',
		navLogin: 'Login',
		navSmInfor: 'My Info',
		siteMapExplore: 'Explore',
		siteMapWallet: 'WEX Wallet',
		siteMapWexex: 'WEXEX',
		siteMapParticipate: 'Participate',
		siteMapBlog: 'Blog',
		siteMapEvent: 'Event',
		siteMapAbout: 'About',
		siteMapPrivacy: 'Privacy Policy',
		siteMapCompany: 'Company information',
		siteMapContact: 'Contact Us',
		siteMapCustomer: 'Customer Support',
		siteMapListing: 'Listing request',
		siteMapIEO: 'IEO Application',
		siteMapPartner: 'PartnerShip',
		navAbout: 'ABOUT US',
		navContent: 'Content',
		navSubMining: 'Mining',
		navSubGame: 'Game',
		btnOk: 'OK'
	},

	message: {
		navSubWallet: 'Go to WEX Wallet Home',
		navSubWexex: 'Learn more about WEXEX',
		navSubBlog: 'For the latest updates, go to blog home',
		navSubEvent: 'Check out the event page',
		navSubMining: 'Go to WEX Mining',
		navSubGame: 'Learn more about WEX GameBOX',
		referralMisssing: 'You must have a referral to sign up.',
		referralReward: 'Referral rewards will be applied 10 minutes after signing up.'
	},

	error: {}
};
